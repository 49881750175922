
import Quiz from '@/components/quiz/Quiz.vue';
import { useStore } from '@/store';
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import InspectorWaiting from '@/components/InspectorWaiting.vue';

export default defineComponent({
  name: 'Inspector',
  components: {
    Quiz,
    InspectorWaiting,
  },
  computed: {
    ...mapState(useStore, ['quizState']),
  },
});
