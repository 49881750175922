import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Quiz = _resolveComponent("Quiz")!
  const _component_InspectorWaiting = _resolveComponent("InspectorWaiting")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inspector-wrapper", { fullScreen: !_ctx.quizState }])
  }, [
    (_ctx.quizState)
      ? (_openBlock(), _createBlock(_component_Quiz, {
          key: 0,
          quiz: _ctx.quizState
        }, null, 8, ["quiz"]))
      : (_openBlock(), _createBlock(_component_InspectorWaiting, { key: 1 }))
  ], 2))
}