
import QuizCard from './QuizCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuizQuestion',
  components: {
    QuizCard,
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    currentQuestion: Number,
    totalQuestions: Number,
  },
});
