
import QuizCard from './QuizCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'QuizHint',
  components: {
    QuizCard,
  },
  props: {
    number: {
      type: Number,
      required: true,
    },
    content: String,
  },
});
