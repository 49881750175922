
import QuizQuestion from './QuizQuestion.vue';
import QuizHint from './QuizHint.vue';
import QuizAnswer from './QuizAnswer.vue';
import { defineComponent } from 'vue';
import type { ClientPlayerQuizState } from '@shared/types';

type Quiz = Omit<ClientPlayerQuizState, 'guessing' | 'playerGuessing'>;

export default defineComponent({
  name: 'Quiz',
  components: {
    QuizQuestion,
    QuizHint,
    QuizAnswer,
  },
  props: {
    quiz: {
      type: Object as () => Quiz,
      required: true,
    },
    inspector: Boolean,
    adminState: Number,
  },
});
