
import { defineComponent } from 'vue';
import Loading from './ui/Loading.vue';

export default defineComponent({
  name: 'InspectorWaiting',
  props: {
    name: String,
  },
  components: { Loading },
});
