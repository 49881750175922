import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eff8bb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "quiz" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizQuestion = _resolveComponent("QuizQuestion")!
  const _component_QuizHint = _resolveComponent("QuizHint")!
  const _component_QuizAnswer = _resolveComponent("QuizAnswer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_QuizQuestion, {
      content: _ctx.quiz.question,
      "current-question": _ctx.quiz.quizNumber,
      "total-questions": _ctx.quiz.quizTotal
    }, null, 8, ["content", "current-question", "total-questions"]),
    _createVNode(_component_QuizHint, {
      number: 1,
      content: _ctx.quiz?.hint1,
      class: _normalizeClass({ hidden: _ctx.adminState !== undefined && _ctx.adminState < 1 })
    }, null, 8, ["content", "class"]),
    _createVNode(_component_QuizHint, {
      number: 2,
      content: _ctx.quiz?.hint2,
      class: _normalizeClass({ hidden: _ctx.adminState !== undefined && _ctx.adminState < 2 })
    }, null, 8, ["content", "class"]),
    _createVNode(_component_QuizAnswer, {
      content: _ctx.quiz.answer?.value,
      unit: _ctx.quiz.answer?.unit,
      inspector: _ctx.inspector,
      "analysis-reference": _ctx.quiz?.guess,
      class: _normalizeClass({ hidden: _ctx.adminState !== undefined && _ctx.adminState < 3 })
    }, null, 8, ["content", "unit", "inspector", "analysis-reference", "class"])
  ]))
}