import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44e03124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overline" }
const _hoisted_2 = {
  key: 0,
  class: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuizCard = _resolveComponent("QuizCard")!

  return (_openBlock(), _createBlock(_component_QuizCard, { class: "card" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, "Hinweis " + _toDisplayString(_ctx.number), 1),
      (_ctx.content)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.content), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}