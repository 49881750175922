
import QuizCard from './QuizCard.vue';
import { defineComponent } from 'vue';
import IconButton from '../ui/IconButton.vue';

export default defineComponent({
  name: 'QuizAnswer',
  components: {
    QuizCard,
    IconButton,
  },
  props: {
    content: Number,
    unit: String,
    analysisReference: Number,
    inspector: Boolean,
  },
  data: () => ({
    analysisVisible: false,
    analysisFullyHidden: true,
    analysisFullyHiddenTimeout: null as number | null,
  }),
  computed: {
    absoluteDistance() {
      if (!this.analysisReference || !this.content) return '';

      if (this.analysisFullyHidden) return 'XXXXx';

      const difference = Math.round(this.analysisReference - this.content);

      return `${difference > 0 ? '+' : ''}${difference}`;
    },
    relativeDistance() {
      if (!this.analysisReference || !this.content) return '';

      if (this.analysisFullyHidden) return 'XXX %';

      const difference = this.analysisReference - this.content;

      const relativeDistance =
        Math.round((difference / this.content) * 1000) / 10;

      return `${relativeDistance > 0 ? '+' : ''}${relativeDistance} %`;
    },
  },
  methods: {
    changeAnalysisVisibility(visible: boolean) {
      this.analysisVisible = visible;
      if (visible) {
        if (this.analysisFullyHiddenTimeout) {
          clearTimeout(this.analysisFullyHiddenTimeout);
        }
        this.analysisFullyHidden = false;
      } else {
        this.analysisFullyHiddenTimeout = setTimeout(() => {
          this.analysisFullyHidden = true;
        }, 120);
      }
    },
  },
});
